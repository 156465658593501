import { Injectable } from '@angular/core';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { map, Observable } from 'rxjs';
import { UrlEnum } from '../../enum/url.enum';
import { CallBookingModel } from '../../model/call-booking.model';

@Injectable({
  providedIn: 'root',
})
export class ContactRequestService {
  private callBookingUrl = `${UrlEnum.SHOHAY_NGO}/contact`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public getAllContactRequests(): Observable<any> {
    return this.httpRequestService
      .get(`${this.callBookingUrl}/get-all-contact`)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any[]) =>
          x.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }

  public bookACall(body: CallBookingModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.callBookingUrl}/create`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
