import { Injectable } from '@angular/core';
import { UrlEnum } from '../../enum/url.enum';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { map, Observable } from 'rxjs';
import { CampaignModel } from '../../model/campaign.model';
import { CampaignStatusEnum } from '../../enum/campaign-status.enum';

/////////////////////////////////////////////
//////// THIS SERVICE IS DEPRECATED ////////
///////////////////////////////////////////

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  private campaignApi = `${UrlEnum.SHOHAY_CAMPAIGN}/campaign`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public getAllCampaigns(filterRunning: boolean = true): Observable<any> {
    return this.utilitiesService
      .responseHandlerWithAlert(
        this.httpRequestService.get(`${this.campaignApi}/get-all`, {
          skipAuth: true,
        })
      )
      .pipe(
        map((campaigns: any[]) =>
          campaigns.map((campaign: any) => ({
            ...campaign,
            img_url: JSON.parse(campaign.img_url).sort(
              this.utilitiesService.dynamicSort('order')
            ),
            appeal_img_url: JSON.parse(campaign.appeal_img_url),
          }))
        ),
        map((campaigns: any) =>
          filterRunning
            ? campaigns.filter(
                (element) => element.status === CampaignStatusEnum.RUNNING
              )
            : campaigns
        ),
        map((x: any[]) =>
          x.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }

  public campaignGetById(id: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.campaignApi}/get-by-id/${id}`, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createCampaign(body: CampaignModel): Observable<any> {
    const url = `${this.campaignApi}/create`;
    return this.httpRequestService
      .post(url, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateCampaign(id: string, body: CampaignModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.campaignApi}/update/${id}`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteCampaign(id: string): Observable<any> {
    return this.httpRequestService
      .delete(`${this.campaignApi}/delete/${id}`, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public startCampaign(id: string, startDate: string): Observable<any> {
    return this.httpRequestService
      .patch(
        `${this.campaignApi}/start-campaign/${id}`,
        {
          status: CampaignStatusEnum.RUNNING,
          start_date: startDate,
        },
        { skipAuth: true }
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public stopCampaign(id: string, endDate: string): Observable<any> {
    return this.httpRequestService
      .patch(
        `${this.campaignApi}/stop-campaign/${id}`,
        {
          status: CampaignStatusEnum.STOPPED,
          actual_end_date: endDate,
        },
        { skipAuth: true }
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
